@import (reference) "../../../less/inc/mixins.less";
@import (reference) "../../../less/inc/variables.less";

#main,
.home-top {
  position: relative;
}

.home-top {
  float: left;
  width: 100%;
}

#view-format {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  text-shadow: 0 0 5px rgba(0,0,0, 0.6);
  z-index: 1;
  * {
    margin-bottom: 0;
  }
  h1 {
    display: inline-block;
    font-size: 12px;
    text-transform: uppercase;
    &:after {
      content: ":";
    }
  }
  ul {
    display: inline-block;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    font-size: 12px;
  }
  li {
    display: inline-block;
    border-right: 1px solid white;
    padding: 0 6px;
    &:last-child {
      border-right: 0;
    }
  }
  a {
    color: white;
    font-family: sans-serif;
  }
  .active {
    font-weight: bold;
  }
  .view-list & {
    // change color of the control when the backgorund image is gone
    color: @grayDark;
    text-shadow: none;
    a {
      color: inherit;
    }
  }
}

.home .full-hero {
  min-height: 400px;
  img {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
    display: block;
  }
  &.one-third-width img {
    width: 34.1%;
    float: left;
  }
  &.two-third-width img {
    width: 34.1% * 2;
    float: left;
  }
}

#dark-top {
  margin-top: -28px;
  color: white;
  background-color: black;
  font-family: sans-serif;

  .side-featured {
    h5.byline, h5.top-tag, h4 {
      margin: 0;
    }
    h4 {
      font-size: 18px;
    }
    h4 a {
      color: white;
    }
    h5 {
      font-size: 80%;
    }
    h5.byline {
      color: @grayLighter;
    }
    article {
      border-top: solid 3px rgba(red(@sky), green(@sky), blue(@sky), 0.5);
      padding: 10px 0;
      &:first-child {
        border-top: 0 none;
      }
    }
  }

  .side-series {
    h3 {
      color: @sky;
      font-size: 100%;
      text-transform: uppercase;
      margin: 0;
      a {
        color: @sky;
      }
    }
    h4 {
      font-size: 18px;
      a {
        color: #fff;
      }
    }
    article {
      padding: 5px 0;
    }
    .read-more {
      text-align: right;
      font-size: 80%;
      a {
        color: @sky;
      }
    }
  }

  .span10 {
    background-color: rgba(0, 0, 0, 0.85);
    margin: 0 8.510638302%;
    padding: 25px 30px;
  }
  article {
    margin-bottom: 0;
    border-bottom: none;
  }
  p {
    font-size: 93%;
    line-height: 1.4em;
    margin-bottom: 0;
  }
  h2 {
    font-size: 38px;
    line-height: 1.1em;
    margin-bottom: 0.3em;
  }
  h5 {
    font-size: 100%;
    a {
      color: @sky;
    }
  }
  h2 a {
    color: white;
  }
  .byline,
  .byline a,
  section {
    color: @grayLighter;
  }
  .byline {
    font-weight: bold;
    font-size: 110%;
  }
  .byline a {
    text-transform: uppercase;
  }
  .span4 .top-tag {
    margin-bottom: 0.7em;
  }
  .related-story {
    font-size: 18px;
    line-height: 1.2em;
    margin-left: 0.2em;
    margin-bottom: 1em;
    a {
      color: white;
    }
  }
  .more {
    text-align: right;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 16px;
    a {
      color: @sky;
      &:after {
        content: " »";
      }
    }
  }

  &.overlay {
    background-color: transparent;
    margin-top: 0;
    position: absolute;
    bottom: 50px;
  }
}

#home-secondary {
  position: relative;
  > div {
    background-color: white;
    padding-bottom: 0;
    margin-top: 20px;
  }
  .row-fluid {
    & > .span1,
    & > .span2,
    & > .span3,
    & > .span4,
    & > .span5,
    & > .span6,
    & > .span7,
    & > .span8,
    & > .span9,
    & > .span10,
    & > .span11,
    & > .span12 {
      .widget {
        border: none;
      }
      &:last-child .widget {
        border-right: none;
      }
    }
  }
}

#home-river {
  display: none;
  header {
    *zoom: 1;
    &:before,
    &:after {
      display: table;
      content: "";
    }
    &:after {
      clear: both;
    }
  }
  .hero {
    text-align: center;
    background-color: #eee;
  }
  .span10 {
    margin: 0 auto;
    float: none;
    position: relative;
    &:before {
      height: 25px;
      background-color: white;
      position: absolute;
      top: -25px;
      left: -4%;
      right: -4%;
      content: " ";
    }
  }
}

@media screen and (max-width: 980px) {
  #dark-top.overlay {
    bottom: 15px;
  }
}

@media screen and (max-width: 768px) {
  #dark-top{
    position: relative !important;
    margin-top: -140px !important;
    bottom: auto !important;
    .span10 {
      width: 100%;
      margin: 0 auto;
    }
    .row-fluid {
      .span1,
      .span2,
      .span3,
      .span4,
      .span5,
      .span6,
      .span7,
      .span8,
      .span9,
      .span10,
      .span11,
      .span12 {
        /*float: none;*/
        width: 100%;
        margin: 0;
      }
    }
    .span4 {
      margin-top: 30px !important;
    }
    .related-story {
      margin-left: 0;
    }
    &.overlay {
      background-color: black;
    }
  }

  #home-secondary .row-fluid {
    & > .span1,
    & > .span2,
    & > .span3,
    & > .span4,
    & > .span5,
    & > .span6,
    & > .span7,
    & > .span8,
    & > .span9,
    & > .span10,
    & > .span11,
    & > .span12 {
      float: none;
      width: 100%;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .home .full-hero {
    min-height: 50px;
  }
  #dark-top {
    margin-top: -40px !important;
    .span10 {
      padding: 15px 18px !important;
    }
  }
  #home-river {
    margin-top: 24px;
  }
  #home-river h1 {
    font-size: 36px;
    line-height: 1.1;
  }
  #view-format {
    width: 100%;
    right: 0;
    left: 0;
    text-align: center;
  }
}
